<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t("sidebar.education") }}</h4>
      </template>
      <template v-slot:headerAction>
        <b-button variant="primary" @click="$refs.modalEducation.show()">{{
          $t("app.new")
        }}</b-button>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col sm="12" md="1" class="pr-1 mb-2">
            <b-form-select
              :options="['5', '10', '20', '50', '100']"
              v-model="filter.per_page"
              size="sm"
              @input="getData()"
            ></b-form-select>
          </b-col>
          <b-col lg="3" md="6" sm="12" class="ml-auto mb-2">
            <b-form-group>
              <b-form-input
                style="height: 35px"
                id="filter-input"
                :disabled="
                  !Array.isArray(getEducation.data) ||
                  (getEducation.data.length === 0 && isBusy === false)
                "
                v-model="filter.search"
                type="search"
                :placeholder="$t('app.search')"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="table-responsive">
          <b-table
            class="table mb-0 table-borderless"
            :items="getEducation.data"
            :fields="columns"
            :per-page="getEducation.per_page"
            :busy="
              !Array.isArray(getEducation.data) ||
              getEducation.data.length === 0
                ? true
                : false || educationLoading == true
            "
            @filtered="onFiltered"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <div v-if="educationLoading">
                  <b-spinner type="grow" class="align-middle"></b-spinner>
                  <strong>{{ $t("app.loading") }}...</strong>
                </div>
                <span class="h5" v-else>{{ $t("app.not-found") }}</span>
              </div>
            </template>

            <template v-slot:cell(action)="data">
              <b-button
                variant=" iq-bg-warning rounded"
                class="mr-1"
                size="sm"
                @click="$refs.modalEducation.show(data.item)"
                ><i class="ri-ball-pen-fill ml-1"></i
              ></b-button>
              <b-button
                @click="deleteItem(data.item.id)"
                variant=" iq-bg-danger rounded"
                size="sm"
                ><i class="ri-delete-bin-line ml-1"></i
              ></b-button>
            </template>
          </b-table>
          <b-pagination
            class="mt-3 float-right"
            v-model="filter.page"
            :total-rows="getEducation.total"
            :per-page="getEducation.per_page"
            first-number
            pills
            size="sm"
            @change="getData"
          ></b-pagination>
          <p class="mt-3">
            {{ $t("app.registers") }} {{ getEducation.to }} {{ $t("app.of") }}
            {{ getEducation.total }}. Total
            {{ getEducation.total }}
          </p>
        </div>
      </template>
    </iq-card>
    <modal-form @refresh="getData()" ref="modalEducation" />
  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import modalForm from "./modalForm.vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "Courses",
  components: {
    modalForm,
  },
  mounted() {
    core.index();
    this.debouncedGetAnswer = _.debounce(this.getData, 500);
    this.getData();
  },
  watch: {
    "filter.search": {
      handler() {
        this.debouncedGetAnswer();
      },
    },
  },
  methods: {
    ...mapActions({
      fetchEducation: "fetchEducation",
      deleteEducation: "deleteEducation",
    }),
    getData(page = 1) {
      this.filter.page = page;
      this.fetchEducation(this.filter);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    deleteItem(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t("app.body-confirm-delete"), {
          title: this.$t("app.title-confirm-delete"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success rounded",
          cancelVariant: "outline-danger rounded",
          okTitle: this.$t("app.yes"),
          cancelTitle: this.$t("app.not"),
          headerClass: "text-center",
          bodyClass: "text-center text-danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.deleteEducation(id);
            this.getData();
            if (resp?.status == 200) {
              core.showSnackbar("success", resp.data.message);
            }
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
  data() {
    return {
      isBusy: false,
      course: {
        name: "",
        acronym: "",
      },
      filter: {
        paginate: true,
        page: 1,
        per_page: 10,
        search: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getEducation: "getEducation",
      educationLoading: "educationLoading",
    }),
    columns() {
      return [
        { label: this.$t("education.name"), key: "name", class: "text-center" },
        {
          label: this.$t("education.initials"),
          key: "initials",
          class: "text-center",
        },
        { label: this.$t("app.action"), key: "action", class: "text-center" },
      ];
    },
  },
};
</script>
