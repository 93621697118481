<template>
  <b-modal id="modal-education" hide-footer scrollable>
    <template #modal-header>
      <h4>
        {{ data.id === null ? $t("education.new") : $t("education.edit") }}
      </h4>
    </template>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="new-user-info">
          <b-row class="justify-content-md-center">
            <b-form-group
              class="col-md-12"
              :label="$t('education.name')"
              label-for="name"
            >
              <ValidationProvider
                :name="$t('education.name')"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-input
                  v-model="data.name"
                  type="text"
                  :placeholder="$t('education.name')"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </b-form-group>
            <b-form-group
              class="col-md-12"
              :label="$t('education.initials')"
              label-for="acronym"
            >
              <ValidationProvider
                :name="$t('education.initials')"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-input
                  v-model="data.initials"
                  type="text"
                  :placeholder="$t('education.initials')"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-row>
          <hr />
          <b-button
            :disabled="educationLoading"
            variant="success"
            class="float-right"
            type="submit"
          >
            <span v-if="educationLoading">
              <b-spinner small type="grow"></b-spinner>
              {{ $t("app.loading") }}...
            </span>
            <span v-else>
              {{ $t("app.save") }}
            </span>
          </b-button>
          <b-button
            class="float-right mr-1"
            @click="$bvModal.hide('modal-education')"
            variant="outline-danger"
            type="reset"
          >
            {{ $t("app.cancel") }}
          </b-button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "modalForm",
  mounted() {
    core.index();
  },
  data() {
    return {
      data: {
        id: null,
        name: null,
        initials: null,
      },
    };
  },
  methods: {
    ...mapActions({
      saveForm: "storeEducation",
    }),
    async show(item) {
      this.reserForm();
      if (item) {
        this.data = {
          id: item.id,
          name: item.name,
          initials: item.initials,
        };
      }
      this.$bvModal.show("modal-education");
    },
    async onSubmit() {
      const resp = await this.saveForm(this.data);
      this.$bvModal.hide("modal-education");
      this.$emit("refresh");
      if (resp.status === 201) {
        core.showSnackbar(
          "success",
          !this.data.id
            ? this.$t("app.success-create")
            : this.$t("app.success-edit")
        );
      }
      this.reserForm();
    },
    reserForm() {
      this.data = {
        id: null,
        name: null,
        initials: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      educationLoading: "educationLoading",
    }),
  },
};
</script>
